import { graphql } from "gatsby";
import { createGatsbyPageComponent } from "@shapeable/web";
import { CatalystLayout } from '../../components/entities/catalyst-layout';
export default createGatsbyPageComponent('Catalyst', { layout: CatalystLayout });

export const query = graphql`
  query CatalystQuery($id: ID!) {
    platform {
      catalyst(id: $id) {
        id name path slug __typename _schema { label pluralLabel }
        banner { openGraph { id image { id url } } }
        openGraph { id image { id url } }
        description { id text }
        exampleModel { id text }
        innovations {
          id name slug path __typename
          openGraph { id image { id url } }
        }
      }
    }
  }
`